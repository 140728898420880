import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
// import Girl from "../img/girl.png"
import Logo from "../img/logo.png"
import Kh from "../img/DOM_2022_03_26_6963 1.png"





export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            <div className="content_midle">
                
                {
                    iW>850? <img className="img-screen" src={Kh} alt="img" />:""
                }
                <div className="case">
                    <span>ДОПОМОЖИ <br/>УКРАЇНІ ЗАРАЗ</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Kh} alt="img" />
                    }
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            {/* <div className="dop-info">
                <span className="dop-info_title">ДОПОМОЖІТЬ<br /> УКРАЇНЦЯМ <br /> ОТРИМАТИ ЛІКИ</span><br />
                <span className="dop-info_sub-title">Зібрані кошти будуть направлені в фонд “Ліки для України”. </span><br />
                <span className="dop-info_info">Як працює Фонд?</span>
                <ul className="dop-info_list">
                    <li>Ми залучаємо кошти від донорів в Україні та країнах ЄС, а також співпрацюємо з волонтерами</li>
                    <li>Ми купуємо ліки в Україні, даючи українцям можливість компенсувати вартість ліків із Фонду. Купуючи українські товари, ви підтримуєте економіку країни і наближаєте нас до перемоги</li>
                    <li>Дефіцитні товари, які неможливо придбати в Україні, ми замовляємо за кордоном</li>
                    <li>Ми доставляємо замовлення по всій країні за допомогою своїх кур’єрів і наших логістичних партнерів — Нова Пошта, Укрпошта. Детальніше за посиланням.</li>
                </ul>
            </div> */}
            <span className="footer_title">Зібрані кошти будуть направлені на допомогу постраждалим українським сім'ям від агресії російської федерації</span>
            <span className="footer_sub-title">©2022 БО "БФ "УКРАЇНСЬКА ОСВІТНЯ ФУНДАЦІЯ". All rights reserved.<br/>Фото: Максим Дондюк</span>
        </footer>
    )
}